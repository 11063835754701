import { useContext, useEffect, useState, type FC } from "react";
import NumberWrapper from "../components/Number";
import { getPrizes } from "../repositories/prize";
import { Prize } from "../model/prize";
import { Resident } from "../model/resident";
import { LoadingContext } from "../objects/LoadingContext";
import useSound from "use-sound";
import { getNops, getWinner, updateWinner } from "../repositories/general";
import { errorToast } from "../utils/helper-ui";
import { Modal, Toggle } from "rsuite";
import Bounce from "rsuite/esm/Animation/Bounce";
import { useGlobalAudioPlayer } from "react-use-audio-player";
import { GoMute, GoUnmute } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { randomStr } from "../utils/helper";

interface LotteryPageProps {}

const LotteryPage: FC<LotteryPageProps> = ({}) => {
  const [volumeBGM, setVolumeBGM] = useState(0.75);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [mounted, setMounted] = useState(false);
  const [prizes, setPrizes] = useState<Prize[]>([]);
  const [allPrizes, setAllPrizes] = useState<Prize[]>([]);
  const [selectedPrize, setSelectedPrize] = useState<Prize | null>(null);
  const [selectedAllPrize, setSelectedAllPrize] = useState<Prize | null>(null);
  const [priceIsRunning, setPriceIsRunning] = useState(false);
  const [drawIsRunning, setDrawIsRunning] = useState(false);
  const [interval, setInt] = useState<NodeJS.Timer>();
  const [interval2, setInt2] = useState<NodeJS.Timer>();
  const [interval3, setInt3] = useState<NodeJS.Timer>();
  const [interval4, setInt4] = useState<NodeJS.Timer>();
  const [intervalStep, setIntervalStep] = useState<NodeJS.Timer>();
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [currentNOP, setCurrentNOP] = useState("000000000000000000");
  const [isRunning, setIsRunning] = useState(false);
  const [winner, setWinner] = useState<Resident | null>(null);
  const [nops, setNops] = useState([]);
  const [showWinner, setShowWinner] = useState(false);
  const [backsoundPlayed, setBacksoundPlayed] = useState(false);
  const [isLoadingNop, setIsLoadingNop] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  let intLoading: NodeJS.Timer;
  const [modalWinnerOpen, setModalWinnerOpen] = useState(false);
  const { load, setVolume, pause, play } = useGlobalAudioPlayer();
  const [isBGMOn, setisBGMOn] = useState(false);
  const [isFull, setIsFull] = useState(false);
  const nav = useNavigate();
  const [isGrandPrize, setIsGrandPrize] = useState(false);
  const [stopPrefix, setStopPrefix] = useState(false);
  const [isGetWinner, setIsGetWinner] = useState(false);
  const [numberClass, setNumberClass] = useState<string[]>([]);
  const [grandPrizeStep, setGrandPrizeStep] = useState(0);

  const [playCoin, { stop: stopCoin }] = useSound("/sounds/coin.mp3", {
    playbackRate,
    interrupt: true,
    loop: true,
  });
  const [playWin, { stop: stopWin }] = useSound("/sounds/winning.mp3", {
    playbackRate,
    interrupt: true,
  });
  const [playTada, { stop: stopTada }] = useSound("/sounds/tada.mp3", {
    playbackRate,
    interrupt: true,
    volume: 0.5,
  });
  const [playWheel, { stop: stopWheel }] = useSound("/sounds/wheel.mp3", {
    playbackRate,
    interrupt: true,
    loop: true,
  });
  const [playBing, { stop: stopBing }] = useSound("/sounds/bing.mp3", {
    playbackRate,
    interrupt: true,
    volume: 0.2,
  });

  useEffect(() => {
    setMounted(true);

    setNumberClass(Array(18).fill("number-ready"));
  }, []);

  useEffect(() => {
    if (drawIsRunning) {
      setNumberClass(Array(18).fill("number-ready"));
      setStopPrefix(false);
      startRandomNOP();
      // setTimeout(() => {
      //   setStopPrefix(true)
      // }, 3000)
      setTimeout(() => {
        stopDrawing();
        // setPriceIsRunning(true);
      }, 5000);
    } else {
      stopDrawing();
    }
  }, [drawIsRunning]);
  useEffect(() => {
    if (priceIsRunning) {
      console.log("START PRIZE");
      if (isGrandPrize) {
        setSelectedPrize(prizes[0]);
        setModalWinnerOpen(true);
        setSelectedAllPrize(selectedPrize);
        playWin();

        return;
      } else {
        stopWheel();
        setSelectedAllPrize(null);
        setModalWinnerOpen(true);
        // startRandomPrize();

        // setTimeout(() => {
        //   playWin();
        //   stopRandomPrice();
        // }, 5000);
      }
    } else {
      stopRandomPrice();
    }
  }, [priceIsRunning]);

  useEffect(() => {
    console.log("stopPrefix");
  }, [stopPrefix]);
  const startRandomNOP = () => {
    playCoin();
    const int2 = setInterval(() => {
      const newNop = Array(18)
        .fill(0)
        .map(() => Math.floor(Math.random() * 10));

      // console.log()
      if (stopPrefix) {
        console.log(currentNOP.slice(0, 9));
        // setCurrentNOP(newNop.map((e) => e.toString()).join(""));
      } else {
        setCurrentNOP(newNop.map((e) => e.toString()).join(""));
      }
      setInt2(int2);
    }, 50); // Update angka tiap 100ms

    const int3 = setInterval(() => {
      fetchWinner();
      setInt3(int3);
    }, 50); // Update angka tiap 100ms

    return () => {
      clearInterval(interval3);
      clearInterval(interval2);
    };
  };
  const startRandomPrize = () => {
    setPriceIsRunning(true);
    // playCoin();

    let _interval = setInterval(() => {
      const randomPrize = prizes.filter((e) => !e.is_grand_prize)[
        Math.floor(Math.random() * prizes.length)
      ];
      setSelectedPrize(randomPrize);
    }, 100); // Update hadiah setiap 200ms
    setInt(_interval);
    let _interval4 = setInterval(() => {
      const randomPrize =
        allPrizes[Math.floor(Math.random() * allPrizes.length)];
      setSelectedAllPrize(randomPrize);
    }, 100); // Update hadiah setiap 200ms
    setInt4(_interval4);

    return () => {
      clearInterval(interval);
      clearInterval(interval4);
    };
  };

  useEffect(() => {
    if (!mounted) return;
    intLoading = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 100) {
          return prevProgress + 1;
        }
        return 100;
      });
    }, 50);
    getNopData();
    return () => clearInterval(intLoading);
  }, [mounted]);

  useEffect(() => {
    getAllPrizes();
  }, [mounted, isGrandPrize]);

  useEffect(() => {
    if (!backsoundPlayed) {
      // stopBackSound();
      // pauseBackSound();
      // setVolumeBGM(0.2)
      setVolume(0.2);
      // pauseBackSound();
    } else {
      // playBackSound();
      load("/sounds/backsound.mp3", { autoplay: true, loop: true });
      setisBGMOn(true);
    }
  }, [backsoundPlayed]);

  const getNopData = () => {
    setIsLoading(false);
    getNops()
      .then((v) => v.json())
      .then((v) => {
        setNops(v.data);
      })
      .catch((err) => {
        errorToast(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAllPrizes = () => {
    getPrizes({ page: 1, limit: 0, search: "" })
      .then((res) => res.json())
      .then((res) => {
        setPrizes((res.data as Prize[]).filter((e) => e.is_active));
        setAllPrizes(res.data);
        if (
          (res.data as Prize[]).filter((e) => e.is_active && !e.is_grand_prize)
            .length == 0
        ) {
          setIsGrandPrize(true);
          // alert("ITS TIME TO GRANDPRIZES")
        }
        if ((res.data as Prize[]).filter((e) => e.is_active).length == 0) {
          errorToast("Hadiah sudah diambil semua");
          // setIsGrandPrize(true)
          setIsFull(true);
        }
      });
  };

  useEffect(() => {
    // console.log(prizes)
    if (prizes.length == 0) {
    }
  }, [prizes]);

  const startLottery = () => {
    setIsRunning(true);
    setWinner(null);

    setTimeout(() => {
      fetchWinner();
    }, 3000); // Berhenti setelah 3 detik
  };

  const fetchWinner = async () => {
    try {
      const response = await getWinner(); // Ganti dengan URL API backend Anda
      const respJson = await response.json();
      setWinner(respJson.data[0]);
      // stopCoin();
      // setPriceIsRunning(false);
      // clearInterval(interval);
      // clearInterval(interval2);
      // console.log("STOP");
      // //   console.log(respJson.data[0].nop);
      // setTimeout(() => {
      //   setCurrentNOP(respJson.data[0].nop); // Set NOP pemenang sebagai running number yang berhenti
      //   playWin();
      // }, 100);
    } catch (error) {
      console.error("Error fetching winner:", error);
    }
  };

  useEffect(() => {
    setShowWinner(winner ? true : false);
  }, [winner]);

  const stopRandomPrice = () => {
    setPriceIsRunning(false);

    console.log("STOP PRIZE");

    stopCoin();
    if (winner?.nop) {
      setModalWinnerOpen(true);
      setSelectedAllPrize(selectedPrize);
      clearInterval(interval);
      clearInterval(interval4);
      playWin();
      stopWheel();
      setIsGetWinner(true);
      if (isGrandPrize) {
        let intId = setInterval(() => {
          setGrandPrizeStep((grandPrizeStep) => {
            
            if (grandPrizeStep < 4) {
              playBing();
              return grandPrizeStep + 1;
            }
            return 4;
          });
        }, 2000);
        setIntervalStep(intId);
        // setTimeout(() => {
        //   playWin();
        //   clearInterval(intId)
        //   clearInterval(grandPrizeStep)
        // }, 9000);
      }
    }
  };

  useEffect(() => {
    console.log("Step", grandPrizeStep)
    if (grandPrizeStep == 4) {
      playWin();
      clearInterval(grandPrizeStep)
    }
  }, [grandPrizeStep]);

  const stopDrawing = () => {
    setDrawIsRunning(false);
    console.log("STOP DRAWING");
    clearInterval(interval2);
    clearInterval(interval3);
    const drawStep1 = (
      winner: Resident,
      digits: number[],
      interval: number
    ) => {
      let splitNop = winner.nop.split("").map((e) => parseInt(e));
      let classes = winner.nop.split("").map((e) => "number-win");
      let intPrefix1 = setInterval(() => {
        let prefix1 = Array(digits.length)
          .fill(0)
          .map(() => Math.floor(Math.random() * 10));

        for (let i = 0; i < digits.length; i++) {
          splitNop[digits[i]] = prefix1[i];
          classes[digits[i]] = "number-ready";
          setCurrentNOP(splitNop.map((e) => e.toString()).join(""));

          setNumberClass(classes);
        }
      }, interval);
      playBing();
      return intPrefix1;
    };
    if (winner?.nop) {
      const steps = [
        [10, 11, 12, 13, 14, 15, 16, 17],
        [11, 12, 13, 14, 15, 16, 17],
        [12, 13, 14, 15, 16, 17],
        [13, 14, 15, 16, 17],
        [14, 15, 16, 17],
        [15, 16, 17],
        [16, 17],
        [17],
      ];

      const executeSteps = (index = 0, timeout = 1000) => {
        if (index >= steps.length) {
          setNumberClass(Array(18).fill("number-win"));
          setCurrentNOP(winner.nop);
          setPriceIsRunning(true);
          startDrawingPrize();
          if (prizes.length == 1 && !isGrandPrize) {
            // alert(prizes.length)
            setSelectedPrize(prizes[0]);
            playWin();
            stopRandomPrice();
          }
          if (isGrandPrize) {
            // console.log(winner);
            setSelectedPrize(prizes[0]);
            playWin();
            stopRandomPrice();
          }
          return;
        }

        const intervalId = drawStep1(winner, steps[index], 100);
        setTimeout(() => {
          clearInterval(intervalId);
          executeSteps(index + 1, 1500);
        }, timeout);
      };

      executeSteps();
    }
  };

  const startDrawingPrize = () => {
    playTada();
    console.log("STAR DRAWING PRIZE");
    stopCoin();
    playWheel();
  };

  const renderDrawer = () => (
    <div
      className="w-full h-screen max-w-lg mx-auto bg-yellow-400 aspect-[1080/1920] relative"
      style={{
        backgroundImage: "url(/images/bg.jpg)",
        // backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "50% 45px",
      }}
    >
      <div className="absolute top-2 left-2 z-50">
        {/* <Toggle
          checkedChildren="Grand Prize"
          color="orange"
          checked={isGrandPrize}
          onChange={(checked) => {
            setIsGrandPrize(checked);
          }}
        /> */}
      </div>
      <div className="absolute top-2 right-2 z-50">
        {!isBGMOn ? (
          <GoUnmute
            className="w-4 text-white cursor-pointer"
            onClick={() => {
              play();
              setisBGMOn(true);
            }}
          />
        ) : (
          <GoMute
            className="w-4 text-white cursor-pointer"
            onClick={() => {
              pause();
              setisBGMOn(false);
            }}
          />
        )}
      </div>

      <div className="absolute inset-0 flex flex-col items-center justify-center">
        {/* Konten pengundian */}
        <img src="/images/winner_title.png" alt="" />
        <div className="px-24 ">
          <img src="/images/title.png" alt="" />
        </div>
        <div className="w-full px-4 py-16  flex">
          <div
            className="w-full aspect-[16/6.2] h-full flex flex-col items-center justify-center "
            style={{
              backgroundImage: "url(/images/number_wrapper.png)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="w-full  px-10 py-8 flex flex-col justify-center items-center h-full gap-y-6  relative">
              <div className="flex justify-center  items-center w-full  min-h-4 gap-x-2">
                <NumberWrapper
                  className={numberClass[0]}
                  no={parseInt(currentNOP[0])}
                />
                <NumberWrapper
                  className={numberClass[1]}
                  no={parseInt(currentNOP[1])}
                />
                <div className="bg-blue-900 text-white font-bold text-xl w-[36px] h-[10px] flex items-center justify-center rounded-full"></div>
                <NumberWrapper
                  className={numberClass[2]}
                  no={parseInt(currentNOP[2])}
                />
                <NumberWrapper
                  className={numberClass[3]}
                  no={parseInt(currentNOP[3])}
                />
                <div className="bg-blue-900 text-white font-bold text-xl w-[36px] h-[10px] flex items-center justify-center rounded-full"></div>
                <NumberWrapper
                  className={numberClass[4]}
                  no={parseInt(currentNOP[4])}
                />
                <NumberWrapper
                  className={numberClass[5]}
                  no={parseInt(currentNOP[5])}
                />
                <NumberWrapper
                  className={numberClass[6]}
                  no={parseInt(currentNOP[6])}
                />
                <div className="bg-blue-900 text-white font-bold text-xl w-[36px] h-[10px] flex items-center justify-center rounded-full"></div>
                <NumberWrapper
                  className={numberClass[7]}
                  no={parseInt(currentNOP[7])}
                />
                <NumberWrapper
                  className={numberClass[8]}
                  no={parseInt(currentNOP[8])}
                />
                <NumberWrapper
                  className={numberClass[9]}
                  no={parseInt(currentNOP[9])}
                />
              </div>
              <div className="flex justify-center  items-center w-full  min-h-4 gap-x-2 px-[40px]">
                <NumberWrapper
                  className={numberClass[10]}
                  no={parseInt(currentNOP[10])}
                />
                <NumberWrapper
                  className={numberClass[11]}
                  no={parseInt(currentNOP[11])}
                />
                <NumberWrapper
                  className={numberClass[12]}
                  no={parseInt(currentNOP[12])}
                />
                <div className="bg-blue-900 text-white font-bold text-xl w-[36px] h-[10px] flex items-center justify-center rounded-full"></div>
                <NumberWrapper
                  className={numberClass[13]}
                  no={parseInt(currentNOP[13])}
                />
                <NumberWrapper
                  className={numberClass[14]}
                  no={parseInt(currentNOP[14])}
                />
                <NumberWrapper
                  className={numberClass[15]}
                  no={parseInt(currentNOP[15])}
                />
                <NumberWrapper
                  className={numberClass[16]}
                  no={parseInt(currentNOP[16])}
                />
                <div className="bg-blue-900 text-white font-bold text-xl w-[36px] h-[10px] flex items-center justify-center rounded-full"></div>
                <NumberWrapper
                  className={numberClass[17]}
                  no={parseInt(currentNOP[17])}
                />
              </div>
              {!isFull && (
                <div className="absolute left-0 right-0 flex justify-center items-end bottom-[-10px]">
                  <img
                    src="/images/draw.png"
                    alt=""
                    className="h-10 cursor-pointer"
                    onClick={() => {
                      setBacksoundPlayed(false);
                      if (drawIsRunning) {
                        setDrawIsRunning(false);
                      } else {
                        setDrawIsRunning(true);
                      }
                      // if (priceIsRunning) {
                      //   setPriceIsRunning(false);
                      // } else {
                      //   setPriceIsRunning(true);
                      // }
                    }}
                  />
                </div>
              )}
             
            </div>
          </div>
        </div>
          {isGrandPrize && (
        <div className="px-24  min-h-[240px] w-full flex justify-center items-center flex-col -mt-20">
            <>
            <img src="/images/grandprize.png" alt="" />
            <img
              src={prizes[0]?.picture_url}
              alt=""
              className="w-[160px]"
              />
              </>
        </div>
          )}
        <div
          className="px-24  w-full flex justify-center items-center flex-col cursor-pointer"
          onClick={() => nav("/leaderboard")}
        >
          <img src="/images/leaderboard.png" alt="" className="w-32" />
        </div>
      </div>
      <Modal
        size={"sm"}
        open={modalWinnerOpen}
        // onClose={() => setModalWinnerOpen(false)}
        style={{ padding: 0 }}
      >
        <Modal.Body>
          <div
            className=" p-4 relative flex justify-center items-center flex-col h-[900px] rounded-lg"
            style={{
              backgroundImage: "url('/images/winning-bg.png')",
              backgroundSize: "cover",
              backgroundPosition: "50% -45px",
            }}
          >
            <div className="px-36">
              <img src="/images/title.png" alt="" className="gelatine" />
            </div>
            <img src="/images/congrat.png" alt="" className="w-[60%]" />
            {renderWinner()}

            <div className=" flex justify-center items-center gap-8 mt-8">
              <img
                src="/images/batal.png"
                alt=""
                className="h-10 cursor-pointer"
                onClick={() => {
                  setModalWinnerOpen(false);
                  setSelectedPrize(null);
                  setSelectedAllPrize(null);
                  setCurrentNOP("000000000000000000");
                  setIsRunning(false);
                  setWinner(null);
                  setIsGetWinner(false);
                  setGrandPrizeStep(0);
                  clearInterval(intervalStep);
                  setPriceIsRunning(false)
                }}
              />
              {selectedPrize && !priceIsRunning && (
                <img
                  src="/images/simpan.png"
                  alt=""
                  className="h-10 cursor-pointer"
                  onClick={() => {
                    updateWinner(winner?.id!, selectedPrize?.id!).then(() => {
                      getAllPrizes();
                      setModalWinnerOpen(false);
                      setSelectedPrize(null);
                      setSelectedAllPrize(null);
                      setCurrentNOP("000000000000000000");
                      setIsRunning(false);
                      setWinner(null);
                      setGrandPrizeStep(0);
                      clearInterval(intervalStep);
                    });
                  }}
                />
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
  const renderWinner = () => {
    if (isGrandPrize) {
      return (
        <>
          <div className="flex flex-row justify-center my-2">
            {currentNOP.split("").map((e, i) => (
              <NumberWrapper
                className={numberClass[i]}
                key={randomStr(10)}
                no={parseInt(e)}
              />
            ))}
          </div>
          {grandPrizeStep >= 1 && (
            <div className="bg-[#23558A] min-w-[260px] max-w-[400px]  px-4 py-2 text-white text-center text-2xl my-4 rounded-3xl relative">
              <div className="-top-2 left-0   absolute text-sm bg-orange-400 px-2 rounded-2xl">
                Kecamatan
              </div>
              {winner?.district_name}
            </div>
          )}
          {grandPrizeStep >= 2 && (
            <div className="bg-[#23558A] min-w-[260px] max-w-[400px]  px-4 py-2 text-white text-center text-2xl my-4 rounded-3xl relative">
              <div className="-top-2 left-0   absolute text-sm bg-orange-400 px-2 rounded-2xl">
                Kelurahan
              </div>
              {winner?.sub_district_name}
            </div>
          )}
          {grandPrizeStep >= 3 && (
            <div className="bg-[#23558A] min-w-[260px] max-w-[400px]  px-4 py-2 text-white text-center text-2xl my-4 rounded-3xl relative">
              <div className="-top-2 left-0   absolute text-sm bg-orange-400 px-2 rounded-2xl">
                Alamat
              </div>
              {winner?.address}
            </div>
          )}
          {grandPrizeStep >= 4 && (
            <div className="bg-[#23558A] min-w-[260px] max-w-[400px]  px-4 py-2 text-white text-center text-3xl my-4 rounded-3xl relative">
              <div className="-top-2 left-0   absolute text-sm bg-orange-400 px-2 rounded-2xl">
                Pemenang
              </div>
              {winner?.name}
            </div>
          )}
          <div className="px-24  h-[300px] w-full flex justify-center items-center flex-col">
            {grandPrizeStep >= 4 && isGetWinner && !priceIsRunning && (
              <img src="/images/get.png" alt="" className="w-[80%] my-4" />
            )}
            {grandPrizeStep >= 4 && selectedPrize && (
              <img
                src={selectedPrize?.picture_url}
                alt=""
                className="w-[200px]"
              />
            )}
          </div>

        </>
      );
    }
    return (
      <>
        <div className="flex flex-row justify-center my-2">
          {currentNOP.split("").map((e, i) => (
            <NumberWrapper
              className={numberClass[i]}
              key={randomStr(10)}
              no={parseInt(e)}
            />
          ))}
        </div>
        <div className="bg-blue-900 p-4 my-4 text-center flex flex-col w-full">
          <span className="mulish text-white text-5xl text-center">
            {winner?.name}
          </span>
          <span className="mulish text-white text-base text-center">
            {winner?.address}
          </span>
        </div>
        <div className="px-24  h-[300px] w-full flex justify-center items-center flex-col">
          {isGetWinner && !priceIsRunning && (
            <img src="/images/get.png" alt="" className="w-[80%] my-4" />
          )}
          {selectedPrize ? (
            <img
              src={selectedPrize?.picture_url}
              alt=""
              className="w-[280px]"
            />
          ) : (
            <div>
              <img
                src="/images/draw.png"
                alt=""
                className="h-16 my-4 cursor-pointer"
                onClick={() => {
                  playWheel();
                  startRandomPrize();
                  setTimeout(() => {
                    playWin();
                    stopRandomPrice();
                  }, 5000);
                }}
              />
            </div>
          )}
        </div>
      </>
    );
  };
  const renderLoading = () => {
    return (
      <div className="flex flex-col  justify-center ">
        {progress < 100 ? (
          <div className="flex flex-col justify-center items-center">
            <div className="w-full bg-gray-200 rounded-full h-4 dark:bg-gray-700 flex flex-col ">
              <div
                className="bg-gradient-to-r from-green-400 via-blue-500 to-purple-600 h-4 rounded-full"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            <p className="text-center">Loading {progress}% ...</p>
          </div>
        ) : (
          <div
            className="flex justify-center w-32 mt-10 cursor-pointer"
            onClick={() => {
              setIsReady(true);
              setBacksoundPlayed(true);
            }}
          >
            <img className="" src="/images/login.png" alt="" />
          </div>
        )}
      </div>
    );
  };

  if (isReady) return renderDrawer();
  return (
    <div
      className="w-full h-screen max-w-lg mx-auto bg-yellow-400 aspect-[1080/1920] relative flex justify-center items-center flex-col"
      style={{
        backgroundImage: "url(/images/loading-bg.jpg)",
        // backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "50% 45px",
      }}
    >
      <div className="px-24 ">
        <img src="/images/title.png" alt="" className="gelatine" />
      </div>
      {renderLoading()}
    </div>
  );
};
export default LotteryPage;
